<template>
  <v-app :class="[appClasses, { 'input-selection-indeterminate': subscriptionBranding && subscriptionBranding.color }]">
    <ProjectNavigationDrawer
      v-if="(homeLayout || projectLayout) && isFullAccessLogin"
    />
    <IdentityCheckingDialog v-if="usesConnectedLayout && showIdentityCheckModal && isFullAccessLogin"
                            @close="SET_SHOW_IDENTITY_CHECK_MODAL(false)"
    />
    <IdentityCheckingLandingDialog
      v-if="usesConnectedLayout && showIdentityCheckLandingModal && isFullAccessLogin && isPreviousRouteLogin"
      @close="SET_SHOW_IDENTITY_CHECK_LANDING_MODAL(false)"
    />
    <ProjectTypeQuoteRequestDialog
      v-if="projectTypeQuoteRequestDialog.isOpen"
      :default-project-type="projectTypeQuoteRequestDialog.defaultProjectType"
      @close="SET_PROJECT_TYPE_QUOTE_REQUEST_DIALOG({ isOpen: false })"
    />
    <DiscoverElectronicSafeDialog
      v-if="discoverElectronicSafeDialogIsOpen"
      @close="SET_DISCOVER_ELECTRONIC_SAFE_DIALOG_IS_OPEN(false)"
      @requestActivation="openRequestElectronicSafeQuoteDialog"
    />

    <transition name="upload-hint-fade">
      <AppFileUploadHint v-if="uploadHint.visible" />
    </transition>

    <v-main>
      <AppFileUpload v-if="isFullAccessLogin"/>
      <div class="fill-height"
           :class="{'px-4': $vuetify.breakpoint.lgAndUp && hasPadding, 'px-0': $vuetify.breakpoint.mdAndDown || !hasPadding}"
      >
        <div v-if="subscriptionBrandingPending" class="fill-height d-flex align-center justify-center">
          <AppLoader/>
        </div>
        <transition v-else name="app-fade">
          <router-view v-if="appIsInitialized && !unauthorizedState"></router-view>
          <div v-if="appIsLoading" class="d-flex align-center justify-center h-100">
            <v-progress-circular
              style="width: 64px; height: 64px"
              color="primary"
              indeterminate
              size="64"
            />
          </div>
          <p v-if="appIsInError" class="d-flex align-center justify-center h-100">
            {{$t('App.initError')}}
          </p>
        </transition>
      </div>
    </v-main>
    <Overlay />
    <PermanentOverlay />

    <AppToastsStack />

    <template v-if="usesConnectedLayout">
      <AppBar :key="profile.id" :fullAccessScope="isFullAccessLogin" />
      <Footer />
    </template>

    <Maintenance v-if="maintenanceModeEnabled"/>
    <!--
      This is based on the assertion that when on a subdomain and going to an unauthorized space,
      you'll soon be redirected by the following logic. Thus we avoid the "overlay flash" that would happen otherwise.
    -->
    <UnauthorizedOverlay v-if="unauthorizedState && !navigatingOnAvailableSubdomain"/>
    <AppVersionChecker v-if="isFullAccessLogin && usesConnectedLayout" @reload="AppStateEnum.loading" />
  </v-app>
</template>

<script>
import * as Sentry from '@sentry/vue'
import axios from 'axios'
import dayjs from 'dayjs'
import { isObject } from 'lodash-es'
import VueRouter from 'vue-router'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

import AppFileUploadHint from '@/common/app-file-upload/AppFileUploadHint.vue'
import AppLoader from '@/common/AppLoader.vue'
import AppVersionChecker from '@/common/AppVersionChecker.vue'
import { AUTH_DATA_COOKIE_KEY } from '@/common/utils/login'
import {
  SUBDOMAIN_COOKIE_KEY,
  getRootDomainFromUrl,
  navigatingOnAvailableSubdomain,
  urlPathJoin,
} from '@/common/utils/url'
import Maintenance from '@/maintenance/Maintenance'
import { GET_AUTH_FROM_TOKEN } from '@/store/modules/login/action_types'
import { SET_USER_TOKEN_SCOPE } from '@/store/modules/login/mutation_types'

import AppBar from './common/app-bar/AppBar'
import AppFileUpload from './common/app-file-upload/AppFileUpload'
import AppToastsStack from './common/AppToastsStack.vue'
import Footer from './common/Footer'
import IdentityCheckingDialog from './common/identity-checking/IdentityCheckingDialog'
import IdentityCheckingLandingDialog from './common/identity-checking/IdentityCheckingLandingDialog'
import Overlay from './common/Overlay'
import PermanentOverlay from './common/PermanentOverlay'
import UnauthorizedOverlay from './common/UnauthorizedOverlay.vue'
import { QUOTE_REQUEST_TOPICS } from './common/utils/quoteRequestTopics'
import {
  getUserScopesFromAuthToken,
  isUserMissingIdCheck,
  USER_ID_STORAGE_KEY,
  USER_TOKEN_STORAGE_KEY,
} from './common/utils/users'
import { AppStateEnum } from './enums'
import i18n from './i18n'
import ProjectNavigationDrawer from './project/navigation-drawer/ProjectNavigationDrawer'
import DiscoverElectronicSafeDialog from './projects/dialogs/DiscoverElectronicSafeDialog.vue'
import ProjectTypeQuoteRequestDialog from './projects/dialogs/ProjectTypeQuoteRequestDialog'
import {
  CERTEUROPE_SIGN_ROUTE_NAME,
  ROOMS_ROUTE_NAME,
  SIGN_IN_ROUTE_NAME,
  SIGNING_CHECKLIST_ADD_ROUTE_NAME,
  NO_AUTH_ROUTE_NAME,
  LOGIN_ARRAY_ROUTE_NAME,
  ROOM_DOCUMENTS_ROUTE_NAME,
} from './router'
import { STORE_PERSIST_KEY } from './store'
import { SET_DISCOVER_ELECTRONIC_SAFE_DIALOG_IS_OPEN } from './store/modules/archives/mutation_types'
import { SET_APP_STATE, SET_IS_PENDO_LOADED } from './store/modules/global/mutation_types'
import { SET_PROJECT_TYPE_QUOTE_REQUEST_DIALOG } from './store/modules/subscription/mutation_types'
import { GET_ACTIVITY_POLLING, GET_USER, GET_ZENDESK_AUTH } from './store/modules/user/action_types'
import { SET_SHOW_IDENTITY_CHECK_LANDING_MODAL, SET_SHOW_IDENTITY_CHECK_MODAL } from './store/modules/user/mutation_types'
import { ENQUEUE_SNACKBAR } from './store/mutation_types'

const ACTIVITY_POLLING_DELAY = 30000

export default {
  name: 'App',
  components: {
    AppBar,
    AppFileUpload,
    AppFileUploadHint,
    AppLoader,
    AppToastsStack,
    AppVersionChecker,
    DiscoverElectronicSafeDialog,
    Footer,
    IdentityCheckingDialog,
    IdentityCheckingLandingDialog,
    Maintenance,
    Overlay,
    PermanentOverlay,
    ProjectNavigationDrawer,
    ProjectTypeQuoteRequestDialog,
    UnauthorizedOverlay,
  },
  errorCaptured (err) {
    // We don't show to the user navigation failures
    if (!VueRouter.isNavigationFailure(err)) {
      console.error(err)
      // this[ENQUEUE_SNACKBAR]({
      //   message: this.$t('common.msgFailErrorOccurred'),
      //   color: 'error',
      // })
    } else {
      // Navigation "errors" are okay to log, but it's not necessarily an error
      console.info(err)
    }
    Sentry.captureException(err)
    return false
  },
  data () {
    return {
      SIGNING_CHECKLIST_ADD_ROUTE_NAME,
      activityPollingTimerId: 0,
      initialized: false,
      AppStateEnum,
    }
  },
  computed: {
    ...mapState(['homeLayout', 'projectLayout', 'routerHistory']),
    ...mapState('user', ['profile', 'showIdentityCheckModal', 'showIdentityCheckLandingModal']),
    ...mapState('maintenance', ['maintenanceModeEnabled']),
    ...mapState('login', ['userTokenScope']),
    ...mapState('subscription', ['projectTypeQuoteRequestDialog', 'subscriptionBranding', 'subscriptionBrandingPending']),
    ...mapState('archives', ['discoverElectronicSafeDialogIsOpen']),
    ...mapState('appUploadHandler', ['uploadHint']),
    ...mapState('room', { roomProcessing: state => state.processing }),
    ...mapState('global', ['isPendoLoaded', 'appState']),
    ...mapGetters('room', ['isCurrentUserPm']),
    ...mapGetters('login', ['isFullAccessLogin']),
    usesConnectedLayout () {
      return this.$route.meta.requiresAuth || this.$route.matched.map(x => x.meta.requiresAuth).includes(true)
    },
    scopeAllowed () {
      return this.userTokenScope.includes(this.$route.meta.scopeAllowed)
    },
    hasPadding () {
      if (this.subscriptionBrandingBackground && !this.usesConnectedLayout) {
        return false
      }

      const pagesWithoutPadding = [
        CERTEUROPE_SIGN_ROUTE_NAME,
        ROOM_DOCUMENTS_ROUTE_NAME,
        SIGNING_CHECKLIST_ADD_ROUTE_NAME,
      ]

      return !pagesWithoutPadding.includes(this.$route.name)
    },
    subscriptionBrandingBackground () {
      return this.subscriptionBranding?.background
    },
    appIsInitialized () {
      return this.appState === AppStateEnum.initialized
    },
    appIsLoading () {
      return this.appState === AppStateEnum.loading
    },
    appIsInError () {
      return this.appState === AppStateEnum.error
    },
    isPreviousRouteLogin () {
      const previousRoute = this.routerHistory.at(-1)
      return LOGIN_ARRAY_ROUTE_NAME.includes(previousRoute)
    },
    // NOTE: This is a computed that checks for multiple possibilities where we shouldn't render
    // And show the Unauthorized modal.
    // By default, all logged-in routes need full access
    // If a scope is specified, then this scope can also access the route
    unauthorizedState () {
      return this.usesConnectedLayout && !this.isFullAccessLogin && !this.scopeAllowed
    },
    navigatingOnAvailableSubdomain () {
      return navigatingOnAvailableSubdomain()
    },
    // Those classes are there primarily to broadcast some app state to the DOM, especially user information
    // Those informations will then be available to Pendo and other third party integrations that can look into the DOM
    appClasses () {
      let appClasses = {}
      if (this.appIsInitialized) {
        // Init with top level classes
        appClasses = {
          'user-has-full-access-login': this.isFullAccessLogin,
          'user-is-sub-user': this.profile.isSubUser,
          'user-is-sub-admin': this.profile.isSubAdmin,
        }
        if (this.projectLayout && !this.roomProcessing) {
          appClasses = {
            ...appClasses,
            'user-is-pm': this.isCurrentUserPm,
            // Added because it's a common use-case
            'user-is-guest': !this.isCurrentUserPm,
          }
        }
      }
      return appClasses
    },
  },
  watch: {
    // Uses a watcher so that if we first go to login, the logic is still done when out
    usesConnectedLayout: {
      async handler (newValue) {
        // We may already have a profile if just logged in or persisted
        if (this.profile.locale) {
          if (i18n.locale !== this.profile.locale) {
            i18n.locale = this.profile.locale
          }
        }
        if (this.profile.id) {
          try {
            Sentry.setUser({ id: this.profile.id })
          } catch {
            console.error('Could not set Sentry user ID')
          }
        }
        if (newValue) {
          this[SET_APP_STATE](AppStateEnum.loading)

          try {
            const userAuthDataFromCookie = this.$cookies.get(AUTH_DATA_COOKIE_KEY)

            if (userAuthDataFromCookie) {
              if (this.navigatingOnAvailableSubdomain) {
                window.location = urlPathJoin(process.env.VUE_APP_CLIENT_URL, this.$route.fullPath)
                return
              }
              this.setTokenFromAuthDataCookie(userAuthDataFromCookie)
            }

            if (localStorage.getItem(USER_TOKEN_STORAGE_KEY)) {
              if (this.isFullAccessLogin) {
                await this.GET_USER()
                if (this.profile.shouldPerformIdCheck && isUserMissingIdCheck(this.profile)) {
                  this.SET_SHOW_IDENTITY_CHECK_LANDING_MODAL(true)
                }
              }
              if (this.profile.locale) {
                if (i18n.locale !== this.profile.locale) {
                  i18n.locale = this.profile.locale
                }
              }
              if (this.$route.name !== ROOMS_ROUTE_NAME && this.isFullAccessLogin) {
                // This inits the AppBar projects menu
                this.getRooms({ mini: true })
              }

              this[SET_APP_STATE](AppStateEnum.initialized)

              if (process.env.NODE_ENV === 'production') {
                window.configureGtag('set', { user_id: this.profile.id })
              }
              if (this.isFullAccessLogin) {
                this.startActivityPolling()
              }

              try {
                this.GET_ZENDESK_AUTH()
              } catch {
                console.error('Couldn\'t set zendesk visitor info')
              }

              this.waitForPendoToBeLoaded()
            }
          } catch (e) {
            console.error(e)
            if (e.request.status !== 401 && e.request.status !== 503) {
              this[SET_APP_STATE](AppStateEnum.error)
              this[ENQUEUE_SNACKBAR]({
                message: this.$t('App.initError'),
                color: 'error',
              })
            }
          }
        } else {
          // If we triggered an init error but got back in login
          // The app shouldn't stay in an error state, we'll try again when we get back inside
          this[SET_APP_STATE](AppStateEnum.initialized)
          clearTimeout(this.activityPollingTimerId)
        }
      },
      immediate: true,
    },
    '$i18n.locale': {
      handler (newValue) {
        document.documentElement.setAttribute('lang', newValue)
        dayjs.locale(newValue)
        this.$vuetify.lang.current = newValue
        // This also reloads guides so they can be displayed in the correct language
        // since we use the locale as a segment
        if (this.isPendoLoaded) {
          window.pendo.updateOptions({ visitor: { locale: newValue } })
        }

        try {
          if (window.zE) {
            window.zE('messenger:set', 'locale', newValue)
          }
        } catch (e) {
          console.error(e)
          console.info('can`t update zendesk locale')
        }
      },
    },
  },
  created () {
    const userAuthDataFromCookie = this.$cookies.get(AUTH_DATA_COOKIE_KEY)

    // Checking if we navigate on a subdomain url,
    // and if auth cookie is present, meaning we are already logged in
    // This logic is done in usesConnectedLayout for logged pages access and here for non-logged ones
    if (this.navigatingOnAvailableSubdomain && userAuthDataFromCookie) {
      window.location = urlPathJoin(process.env.VUE_APP_CLIENT_URL, this.$route.fullPath)
      return
    }

    if (userAuthDataFromCookie) {
      this.setTokenFromAuthDataCookie(userAuthDataFromCookie)
    }

    try {
      // Checking for corrupted user state. This would bork a lot of things if we can't access the user.
      // User is always an object in its default state and is hydrated with data otherwise.
      // Profile may also (seen in V2-2071) be in a wrong form.
      // Context: V2-1373 and V2-2071
      if (!this.$store.state.user || !this.$store.state.user?.profile || !isObject(this.$store.state.user.profile)) {
        this.fatalStateErrorHandler()
        return
      }
    } catch (error) {
      // Wew, something _very_ wrong happened here !
      console.error(error)
      this.fatalStateErrorHandler()
    }
    const token = localStorage.getItem(USER_TOKEN_STORAGE_KEY)
    const localeLanguage = i18n.locale
    document.documentElement.setAttribute('lang', localeLanguage)
    dayjs.locale(localeLanguage)
    this.$vuetify.lang.current = localeLanguage

    this.waitForZendeskToBeLoaded(localeLanguage)

    if (!token && this.usesConnectedLayout) {
      // Bare push here without the common utils because we already have the locale
      // We also need to pass in the query parameters in case of redirects especially
      this.$router.push({ name: SIGN_IN_ROUTE_NAME, params: { locale: localeLanguage, returnRoute: this.$route }, query: this.$route.query })
    }
  },
  mounted () {
    window.addEventListener('storage', () => {
      // If the profile value is undefined, and we get a null in storage, those are equal
      // eslint-disable-next-line eqeqeq
      if (localStorage.getItem(USER_ID_STORAGE_KEY) != this.profile?.id) {
        // Fully refresh if we were in a logged-in route
        // it seems we were deconnected elsewhere and have a new user
        if (!this.$route.matched.find((route) => route.name === NO_AUTH_ROUTE_NAME)) {
          this.$router.go()
        }
      }
    })
  },
  methods: {
    ...mapActions('login', [GET_AUTH_FROM_TOKEN]),
    ...mapActions('user', [GET_ACTIVITY_POLLING, GET_USER, GET_ZENDESK_AUTH]),
    ...mapActions('rooms', ['getRooms']),
    ...mapMutations([ENQUEUE_SNACKBAR]),
    ...mapMutations('user', [SET_SHOW_IDENTITY_CHECK_MODAL, SET_SHOW_IDENTITY_CHECK_LANDING_MODAL]),
    ...mapMutations('login', [SET_USER_TOKEN_SCOPE]),
    ...mapActions('subscription', [SET_PROJECT_TYPE_QUOTE_REQUEST_DIALOG]),
    ...mapMutations('archives', [SET_DISCOVER_ELECTRONIC_SAFE_DIALOG_IS_OPEN]),
    ...mapMutations('global', [SET_IS_PENDO_LOADED, SET_APP_STATE]),
    initPendo () {
      try {
        const pendoOptions = {
          visitor: {
            id: process.env.VUE_APP_ENVIRONNEMENT + '-' + this.profile.id,
            full_name: this.profile.fullName,
            email: this.profile.email,
            telephone: this.profile.telephone,
            locale: this.profile.locale,
            company: this.profile.company,
            isActive: this.profile.isActive,
            missingInfoToSign: this.profile.missingInfoToSign,
            shouldPerformIdCheck: this.profile.shouldPerformIdCheck,
            isArchivingAdmin: this.profile.isArchivingAdmin,
            isSubAdmin: this.profile.isSubAdmin,
            isSubArchivingAdmin: this.profile.isSubArchivingAdmin,
            isSubBillingAdmin: this.profile.isSubBillingAdmin,
            isSubUser: this.profile.isSubUser,
            hostname: location.hostname,
          },
          events: {
            ready: () => {
              // By only checking a variable we control that is set by Pendo itself,
              // we can be sure it _really_ loaded without checking Pendo directly
              // and possibly having V2-1803 triggering
              this.SET_IS_PENDO_LOADED(true)
            },
          },
        }

        if (this.profile.subscription) {
          pendoOptions.account = {
            id: process.env.VUE_APP_ENVIRONNEMENT + '-' + this.profile.subscription.id,
            name: this.profile.subscription.name,
            company: this.profile.subscription.company,
            address1: this.profile.subscription.address1,
            address2: this.profile.subscription.address2,
            city: this.profile.subscription.city,
            zip: this.profile.subscription.zip,
            country: this.profile.subscription.country,
            archivingEnabled: this.profile.subscription.archivingEnabled,
            isDRSub: !this.profile.subscription.hideDataroomOption,
            isTMSub: !this.profile.subscription.hideProjectOption,
            isM365Sub: this.profile.subscription.wopiOfficeEnabled,
          }
        }
        window.pendo.initialize(pendoOptions)
      } catch (error) {
        console.info('Couldn\'t set Pendo visitor info')
        console.error(error)
      }
    },
    initZendesk () {
      try {
        const scriptTag = document.createElement('script')
        scriptTag.type = 'text/javascript'
        scriptTag.defer = true
        scriptTag.id = 'ze-snippet'
        scriptTag.src = 'https://static.zdassets.com/ekr/snippet.js?key=dc3651e4-1249-472e-89ca-b78b5a76ca41'

        var s = document.getElementsByTagName('script')[0]
        s.parentNode.insertBefore(scriptTag, s)
      } catch (e) {
        console.error(e)
        console.info('zendesk init failed')
      }
    },
    waitForPendoToBeLoaded () {
      this.checkForPendoInterval = setInterval(() => {
        if (window.pendo && this.profile?.id) {
          this.initPendo()
          clearInterval(this.checkForPendoInterval)
        }
      }, 200)
    },
    waitForZendeskToBeLoaded (localeLanguage) {
      this.initZendesk()

      this.zendeskInterval = setInterval(() => {
        if (window.zE) {
          window.zE('messenger:set', 'locale', localeLanguage)
          clearInterval(this.zendeskInterval)
        }
      }, 200)
    },
    startActivityPolling () {
      try {
        this.GET_ACTIVITY_POLLING()
      } finally {
        this.activityPollingTimerId = setTimeout(() => {
          this.startActivityPolling()
        }, ACTIVITY_POLLING_DELAY)
      }
    },
    fatalStateErrorHandler () {
      this[SET_APP_STATE](AppStateEnum.error)
      console.error('Broken state ! Forcefully resetting it.')
      Sentry.setUser({ id: localStorage.getItem(USER_ID_STORAGE_KEY) })
      Sentry.captureMessage('😨 The state somehow got corrupted', {
        contexts: {
          'User Object': this.$store.state.user,
        },
      })
      // Same logic as in when we do it in the regular logout
      // The instructions are done in an immediately queued timer so that the persist already handled the changes it needed to do
      // And we can then remove it entirely
      setTimeout(() => {
        this.removeAuthDataCookie()
        this.removeSubdomainDataCookie()
        localStorage.removeItem(STORE_PERSIST_KEY)
        localStorage.removeItem(USER_TOKEN_STORAGE_KEY)
        localStorage.removeItem(USER_ID_STORAGE_KEY)
        const locationUrl = new URL(window.location.href)
        locationUrl.searchParams.set('isVolontaryLogOut', false)
        locationUrl.searchParams.set('isLogOut', true)
        // Boot the app again, it'll do the logout sequence and we'll make it look like an expired session.
        window.location = locationUrl.toString()
      }, 0)
    },
    removeAuthDataCookie () {
      const clientAppUrl = process.env.VUE_APP_CLIENT_URL
      const cookieDomain = getRootDomainFromUrl(clientAppUrl)
      this.$cookies.remove(AUTH_DATA_COOKIE_KEY, null, cookieDomain)
    },
    setTokenFromAuthDataCookie (userAuthDataFromCookie) {
      const { token, userId } = userAuthDataFromCookie
      localStorage.setItem(USER_ID_STORAGE_KEY, userId)
      localStorage.setItem(USER_TOKEN_STORAGE_KEY, token)
      axios.defaults.headers.common.Authorization = `Bearer ${token}`

      const userScopes = getUserScopesFromAuthToken()
      if (userScopes) {
        this.SET_USER_TOKEN_SCOPE(userScopes)
      }
    },
    removeSubdomainDataCookie () {
      const clientAppUrl = process.env.VUE_APP_CLIENT_URL
      const cookieDomain = getRootDomainFromUrl(clientAppUrl)
      this.$cookies.remove(SUBDOMAIN_COOKIE_KEY, null, cookieDomain)
    },
    openRequestElectronicSafeQuoteDialog () {
      this.SET_PROJECT_TYPE_QUOTE_REQUEST_DIALOG({
        isOpen: true,
        defaultProjectType: QUOTE_REQUEST_TOPICS.ELECTRONIC_SAFE,
      })
    },
  },
}
</script>

<style lang="scss">
  @import "scss/main";
// TODO : Move this remaining styles in their respective components when they'll be created
.v-alert__content {
  color: #333;
}
.v-tab:hover {
  background-color: var(--v-grey-lighten5) !important;
}

/* group chips in task */
.v-slide-group__next {
  min-width: 30px;
  margin-right: -10px;
}
.v-slide-group__prev {
  min-width: 30px;
  margin-left: -10px;
}
.c-affix.is-fixed {
  z-index: 1 !important;
}

.v-tab {
  text-transform: none;
}

// input--indeterminate is set in _vuetify-override.js
// Disable checklists have this default color rgba(255, 114, 118, 0.38)
// For vuetify theme customization, we don't want to keep this behavior
.input-selection-indeterminate {
  .theme--light.v-input--selection-controls.v-input--is-disabled:not(.v-input--indeterminate) .v-icon {
    color: rgba(0, 0, 0, 0.38) !important;
  }
}

.v-input__append-inner {
  // Explicit height to center things, accounting default margins
  height: 26px;
  align-items: center;
}
.v-list-item__content {
  color: #333;
}
.theme--light.v-list-item.v-list-item--highlighted::before {
  background-color: #5076be;
}
.theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before{
  opacity: 0.16 !important;
}

.v-text-field--outlined.v-input--is-focused fieldset, .v-text-field--outlined.v-input--has-state fieldset{
  border: 1px solid currentColor;
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 4px 0 12px;
}
.v-application--is-ltr .v-text-field__suffix {
  margin-right: $spacer * 4;
}

.v-stepper__header .v-stepper__step--active .v-stepper__step__step {
  background-color: var(--v-tertiary-base) !important;
}

.v-stepper__header {
  box-shadow: none;
}

.v-input__icon.v-input__icon--clear .fa-xmark:hover {
  color: #E02020;
}

.v-pagination__navigation .v-icon {
  font-size: 16px;
}

.v-data-table-header__icon {
  margin-left: 6px;
}

.v-data-table .v-data-footer > span {
  color: #4B93FF;
  background-color: #F0F6FF;
  border: 1px solid #DBE9FF;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.v-breadcrumbs {
  padding: 24px 12px 18px 12px;
}

.v-breadcrumbs ul {
  padding: 2px 0 12px 0;
}

.v-icon.v-treeview-node__checkbox {
  font-size: 20px;
}

.v-card__actions > .v-btn.v-btn {
  padding: 0 16px;
}

.v-data-table__mobile-row {
  border-top: 1px solid #e0e0e0;
}

.fa-stack {
  // If we don't do that, adding a stack will move the icon to it's unstacked version
  vertical-align: baseline;
}

.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #FAFBFC;
}

.v-data-table {
  .v-data-footer .v-data-footer__select .v-select {
    margin-left: 16px;
  }
  .v-data-footer__pagination {
    display: none !important;
  }
}

// We swap the usual sort-up icon with the "caret-up" icon
// because this one keep its vertical alignment
.v-data-table-header__icon.fa-sort-up::before {
  content: "\f0d8";
}

.v-label {
  font-size: 14px;
}

.v-list-item__title {
  font-size: 14px !important;
  line-height: 1.3 !important;
}

.v-select.v-input--dense .v-select__selection--comma {
  line-height: 1.3;
}

.v-application .caption, .v-application .overline {
  font-size: .85rem !important;
}

.v-input--selection-controls__input .v-icon {
  font-size: 20px;
}

.v-dialog {
  border-radius: 8px;
}

.v-dialog .v-card {
  border-radius: 0;
}

.v-dialog > .v-card > .v-card__title {
  font-size: 16px;
}

.v-dialog > .v-card > .v-card__subtitle {
  font-size: 14px;
}

.v-application--is-ltr .v-alert__icon {
  margin-right: 8px;
}

.v-data-footer {
  .fa-chevron-left, .fa-chevron-right {
    font-size: 20px;
  }
  .fa-caret-down {
    font-size: 18px;
  }
}

.Closd-hoverArea {
  cursor: pointer;
  padding: .25rem;
  border: 1px dashed transparent;
}

.selected-items-bar {
  display: flex;
  border: 1px solid var(--v-primary-base);
  align-items: center;
  flex-wrap: wrap;
  padding: 0 $spacer * 5;
  border-radius: $border-radius-root * 2;
}

.selected-items-count {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.Closd-hoverArea:hover {
  background-color: #f0f0f0;
  border-color: #c2c2c2;
}

.v-treeview-node__prepend {
  // Otherwise we can have dead space when the prepend is empty in AppFildersTree.
  // This is a workaround, we may have a prop in the future to control this
  min-width: auto;
}

.v-input__icon.v-input__icon--clear .fa-xmark:hover {
  color: #E02020;
}

.v-pagination__navigation .v-icon {
  font-size: 16px;
}

.v-data-table-header__icon {
  margin-left: 6px;
}

.v-breadcrumbs ul {
  padding: 2px 0 12px 0;
}

.v-icon.v-treeview-node__checkbox {
  font-size: 20px;
}

html {
  font-size: 14px;
}

.v-card__actions > .v-btn.v-btn {
  padding: 0 16px;
}

.v-data-table__mobile-row {
  border-top: 1px solid #e0e0e0;
}

.fa-stack {
  // If we don't do that, adding a stack will move the icon to it's unstacked version
  vertical-align: baseline;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 4px 16px;
}

.upload-hint-fade-leave-active {
  transition: all .12s ease-in-out;
}

.upload-hint-fade-leave-to {
  bottom: 15px !important;
  opacity: 0;
}
</style>
