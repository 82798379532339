<template>
  <div class="envelope-template-creation fill-height">
    <v-stepper v-model="currentStep"
               class="envelope-template-creation__stepper"
               color="success"
    >
      <v-stepper-header class="envelope-template-creation__stepper-header">
        <v-stepper-step :step="TemplateCreationSteps.DOCUMENTS_SELECTION">
          {{ $t('project.views.EnvelopeTemplateAdd.documentsSelectionStepTitle') }}
        </v-stepper-step>
        <v-divider  />
        <v-stepper-step :step="TemplateCreationSteps.TEMPLATE_SETTINGS">
          {{ $t('project.views.EnvelopeTemplateAdd.templateSettingsStepTitle') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :step="TemplateCreationSteps.SIGNATURE_TAGS">
          {{ $t('project.views.EnvelopeTemplateAdd.signatureTagsStepTitle') }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items class="envelope-template-creation__stepper-items"
                       :class="{'fill-height' : currentStep !== TemplateCreationSteps.SIGNATURE_TAGS}"
      >
        <v-stepper-content :step="TemplateCreationSteps.DOCUMENTS_SELECTION">
          <EnvelopeTemplateAddDocumentsSelection v-if="currentStep === TemplateCreationSteps.DOCUMENTS_SELECTION" />
        </v-stepper-content>
        <v-stepper-content :step="TemplateCreationSteps.TEMPLATE_SETTINGS">
          <template v-if="isEnvelopeTemplatePending">
            <div class="text-center">
              <AppLoader />
            </div>
          </template>
          <template v-else>
            <EnvelopeTemplateAddSettings v-if="currentStep === TemplateCreationSteps.TEMPLATE_SETTINGS"
                                         :formMode="formMode"
                                         :validator="$v"
            />
          </template>
        </v-stepper-content>
        <v-stepper-content :step="TemplateCreationSteps.SIGNATURE_TAGS">
          <EnvelopeTemplateAddSignatureTags v-if="currentStep === TemplateCreationSteps.SIGNATURE_TAGS"
                                            :envelope-template-to-prepare="envelopeTemplatesToPrepare[envelopeTemplatesToPrepareIndex]"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <div class="stepper__actionsBar envelope-template-creation__actions">
      <EnvelopeTemplateAddFormActions :current-step="currentStep"
                                      :form-mode="formMode"
                                      :envelope-templates-to-prepare="envelopeTemplatesToPrepare"
                                      :envelope-templates-to-prepare-index="envelopeTemplatesToPrepareIndex"
                                      :form="$v"
                                      @cancel="onCancel"
                                      @exit="returnToSigningChecklist"
                                      @next="currentStep++"
                                      @next-document="envelopeTemplatesToPrepareIndex++"
                                      @previous="onPreviousStep"
                                      @previous-document="envelopeTemplatesToPrepareIndex--"
                                      @save-and-exit="onSaveAndExit"
                                      @save-and-next="onSaveAndNext"
      />
    </div>

    <SigningChecklistAddBeforeLeaveDialog
      v-if="showBeforeLeaveDialog"
      @confirm="onSaveAndExit"
      @close="$router.push(leaveLocation)"
    />
    <SigningChecklistPrepareCancelDialog
      v-if="showCancelDialog"
      @confirm="returnToSigningChecklist(true)"
      @close="showCancelDialog = false"
    />
  </div>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash-es'
import { defineComponent, PropType } from 'vue'
import { validationMixin } from 'vuelidate'
import { maxValue, minValue, numeric, required, requiredIf } from 'vuelidate/lib/validators'
import { mapGetters, mapMutations, mapState } from 'vuex'

import AppLoader from '@/common/AppLoader.vue'
import { type FormMode } from '@/common/utils/form'
import { SHARE_TYPE_OPTIONS } from '@/common/utils/shareTypes'
import { typedMapActions, typedMapMutations } from '@/common/utils/store'
import { ENVELOPE_TYPES, SIGNATURE_PROVIDER } from '@/project/signing-checklist/constants'
import SigningChecklistAddBeforeLeaveDialog from '@/project/signing-checklist/dialogs/SigningChecklistAddBeforeLeaveDialog.vue'
import SigningChecklistPrepareCancelDialog from '@/project/signing-checklist/dialogs/SigningChecklistPrepareCancelDialog.vue'
import EnvelopeTemplateAddDocumentsSelection
  from '@/project/signing-checklist/envelope-templates/add/EnvelopeTemplateAddDocumentsSelection.vue'
import EnvelopeTemplateAddFormActions
  from '@/project/signing-checklist/envelope-templates/add/EnvelopeTemplateAddFormActions.vue'
import EnvelopeTemplateAddSettings
  from '@/project/signing-checklist/envelope-templates/add/EnvelopeTemplateAddSettings.vue'
import EnvelopeTemplateAddSignatureTags
  from '@/project/signing-checklist/envelope-templates/add/EnvelopeTemplateAddSignatureTags.vue'
import { LOGOUT_ROUTE_NAME, SIGNING_CHECKLIST_ROUTE_NAME } from '@/router'
import { type EnvelopeTemplatesActionType } from '@/store/modules/envelope-templates/action_types'
import { type EnvelopeTemplatesMutationType } from '@/store/modules/envelope-templates/mutation_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

export enum TemplateCreationSteps {
  DOCUMENTS_SELECTION = 1,
  TEMPLATE_SETTINGS = 2,
  SIGNATURE_TAGS = 3,
}

export type EnvelopeTemplateToPrepare = {
  id: number
  envelopeType: ENVELOPE_TYPES
  provider: SIGNATURE_PROVIDER
  signatureType: string
}

type DataType = {
  currentStep: TemplateCreationSteps,
  envelopeTemplatesToPrepare: Array<EnvelopeTemplateToPrepare>
  envelopeTemplatesToPrepareIndex: number
  leaveLocation: object | null
  showBeforeLeaveDialog: boolean
  showCancelDialog: boolean
  isEnvelopeTemplatePending: boolean
  initialTemplatesState: Array<object> | null
}

const sharedWithValidator = (currentUserId) => (withUsers, objectToPostModel) => {
  if (objectToPostModel.shareType === SHARE_TYPE_OPTIONS.CUSTOM) {
    return withUsers.filter(userId => userId !== currentUserId).length > 0
  }

  return true
}

export default defineComponent({
  name: 'EnvelopeTemplateAdd',
  components: {
    AppLoader,
    EnvelopeTemplateAddSignatureTags,
    EnvelopeTemplateAddFormActions,
    EnvelopeTemplateAddDocumentsSelection,
    EnvelopeTemplateAddSettings,
    SigningChecklistAddBeforeLeaveDialog,
    SigningChecklistPrepareCancelDialog,
  },
  props: {
    formMode: {
      type: String as PropType<FormMode>,
      default: 'creation',
    },
  },
  data (): DataType {
    return {
      currentStep: this.formMode === 'edition'
        ? TemplateCreationSteps.TEMPLATE_SETTINGS
        : TemplateCreationSteps.DOCUMENTS_SELECTION,
      envelopeTemplatesToPrepare: [],
      envelopeTemplatesToPrepareIndex: 0,
      leaveLocation: null,
      showBeforeLeaveDialog: false,
      showCancelDialog: false,
      isEnvelopeTemplatePending: false,
      initialTemplatesState: null,
    }
  },
  computed: {
    ...mapGetters('room', ['isCurrentUserPm']),
    ...mapGetters('user', ['currentUserId']),
    ...mapState('envelopeTemplates', ['envelopeTemplates', 'envelopeTemplateDrafts']),
    ...mapState('room', ['currentRoom', 'roomProvidersPending']),
    TemplateCreationSteps () { return TemplateCreationSteps },
  },
  metaInfo () {
    return {
      title: this.$t('project.views.EnvelopeTemplateAdd.metaInfo.title').toString(),
    }
  },
  async created () {
    if (this.formMode === 'edition' && this.$route.query.envelopeTemplateId) {
      try {
        this.isEnvelopeTemplatePending = true
        await this.UPDATE_DRAFT_TEMPLATE_DATA(this.$route.query.envelopeTemplateId)
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(
            this.$t('project.views.EnvelopeTemplateAdd.updateDraftError'),
        )
      } finally {
        this.isEnvelopeTemplatePending = false
        this.initialTemplatesState = cloneDeep(this.envelopeTemplateDrafts)
      }
    }
  },
  methods: {
    ...typedMapMutations<EnvelopeTemplatesMutationType>('envelopeTemplates', ['RESET_DRAFT_ENVELOPE_TEMPLATES', 'SET_TEMPLATE_DOCUMENT_ID_AFTER_CREATION_SUCCESS']),
    ...typedMapActions<EnvelopeTemplatesActionType>('envelopeTemplates', ['PATCH_ENVELOPE_TEMPLATE', 'POST_ENVELOPE_TEMPLATE', 'UPDATE_DRAFT_TEMPLATE_DATA']),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    onCancel () {
      if (this.currentStep > 1) {
        this.showCancelDialog = true
      } else {
        this.returnToSigningChecklist()
      }
    },
    onPreviousStep () {
      if (this.currentStep === TemplateCreationSteps.SIGNATURE_TAGS) {
        this.envelopeTemplatesToPrepare = []
      }

      this.currentStep -= 1
    },
    async onSaveAndExit () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        await this.saveEnvelopeTemplates(null, true)

        if (!this.leaveLocation) {
          this.returnToSigningChecklist()
        }
      }
    },
    async onSaveAndNext () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        await this.saveEnvelopeTemplates()

        if (this.envelopeTemplatesToPrepare.length > 0) {
          this.currentStep++
        }
      }
    },
    async saveEnvelopeTemplates (envelopeTemplates: object[] | null = null, displaySuccessMessage = false) {
      const envelopeTemplatesArray = envelopeTemplates || this.envelopeTemplateDrafts
      const envelopeTemplatesPayload = envelopeTemplatesArray.map(templateDraft => {
        const { document, templateId } = templateDraft

        return {
          templateId: templateId,
          envelopeType: document.objectToPost.envelopeType,
          fileId: document.objectToPost.fileId,
          sendNotif: document.objectToPost.sendNotif,
          shareType: document.objectToPost.shareType,
          signatureType: document.objectToPost.signatureType,
          signers: document.objectToPost.signers,
          title: document.objectToPost.title,
          withUsers: document.objectToPost.withUsers,
        }
      })

      const envelopeTemplatesPromises = envelopeTemplatesPayload.map(payload => {
        const saveTemplateSuccessCallback = response => {
          this.SET_TEMPLATE_DOCUMENT_ID_AFTER_CREATION_SUCCESS({
            fileId: payload.fileId,
            templateId: response.envelopeTemplate.id,
          })

          return response
        }

        if (this.formMode === 'edition') {
          return this.PATCH_ENVELOPE_TEMPLATE(payload)
            .then(saveTemplateSuccessCallback)
        } else {
          return this.POST_ENVELOPE_TEMPLATE(payload)
            .then(saveTemplateSuccessCallback)
        }
      })

      const promisesResult = await Promise.allSettled(envelopeTemplatesPromises)

      promisesResult.forEach(promiseResult => {
        if (promiseResult.status === 'fulfilled') {
          if (displaySuccessMessage) {
            if (this.formMode === 'edition') {
              this.ENQUEUE_SUCCESS_SNACKBAR(
                  this.$t(
                      'project.views.EnvelopeTemplateAdd.patchEnvelopeTemplateSuccess',
                      { templateTitle: promiseResult.value.envelopeTemplate.title },
                  ),
              )
            } else {
              this.ENQUEUE_SUCCESS_SNACKBAR(
                this.$t(
                  'project.views.EnvelopeTemplateAdd.postEnvelopeTemplateSuccess',
                  { templateTitle: promiseResult.value.envelopeTemplate.title },
                ),
              )
            }
          }

          const { envelopeTemplate } = promiseResult.value
          this.envelopeTemplatesToPrepare.push({
            id: envelopeTemplate.id,
            envelopeType: envelopeTemplate.envelopeType,
            provider: envelopeTemplate.provider,
            signatureType: envelopeTemplate.signatureType,
          })
        }

        if (promiseResult.status === 'rejected') {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.views.EnvelopeTemplateAdd.postEnvelopeTemplateError'))
        }
      })
    },
    async returnToSigningChecklist (fromCancellation = false) {
      if (this.formMode === 'edition' && fromCancellation) {
        await this.saveEnvelopeTemplates(this.initialTemplatesState, false)
      }
      this.leaveLocation = { name: SIGNING_CHECKLIST_ROUTE_NAME, params: { openDocumentTemplatesDialog: true } }
      this.$router.push(this.leaveLocation)
    },
  },
  mixins: [validationMixin],
  validations () {
    const signersValidator = {
      maxValue: maxValue(150),
      minValue: (value, model) => {
        if (model.envelopeType === ENVELOPE_TYPES.SINGLE) {
          return value >= 1
        }

        return true
      },
      numeric,
      required,
    }

    const objectToPostValidations = {
      title: {
        required,
      },
      signatureType: {
        required,
      },
      signers: signersValidator,
      envelopeType: {
        required,
      },
      shareType: {
        required,
      },
      withUsers: {
        required: sharedWithValidator(this.currentUserId),
      },
    }

    return {
      envelopeTemplateDrafts: {
        $each: {
          document: {
            objectToPost: objectToPostValidations,
          },
        },
      },
    }
  },
  beforeDestroy () {
    this.RESET_DRAFT_ENVELOPE_TEMPLATES()
  },
  beforeRouteLeave (to, from, next) {
    const isNavigationToLogout = to.name === LOGOUT_ROUTE_NAME
    if (isNavigationToLogout) {
      next()
    }

    if (this.currentStep === TemplateCreationSteps.TEMPLATE_SETTINGS && this.leaveLocation === null) {
      this.leaveLocation = to
      this.showBeforeLeaveDialog = true
      next(false)
    } else {
      next()
    }
  },
})
</script>

<style lang="scss">
  .envelope-template-creation {
    display: flex;
    flex-direction: column;

    &__stepper.v-stepper {
      background: transparent;
      flex: 1;

      .v-stepper__step--active .v-stepper__step__step {
        background-color: var(--v-primary-base) !important;
      }
      .v-stepper__content {
        padding-bottom: 0;
      }
    }

    &__stepper-header.v-stepper__header {
      justify-content: center;
      max-width: 900px;
      margin: 0 auto;
      height: auto;

      .v-divider {
        flex-basis: 32px;
        flex-grow: 0;
      }
    }

    &__stepper-items.v-stepper__items {
      background: var(--v-white-base);
      border-radius: 8px;
    }

    &__actions {
      background-color: var(--v-grey-lighten5);
      bottom: 0;
      position: sticky;
      z-index: 2;
    }
  }
</style>
