export const SIGNATURE_TYPE_HANDWRITTEN = 'pdf.simple'
export const SIGNATURE_TYPE_DOCUSIGN_ADVANCED = 'ds.advanced'
export const SIGNATURE_TYPE_DOCUSIGN_QUALIFIED = 'ds.qualified'
export const SIGNATURE_TYPE_DOCUSIGN_SIMPLE = 'ds.simple'
export const SIGNATURE_TYPE_CERT_EUROPE_ADVANCED = 'oo.advanced'
export const SIGNATURE_TYPE_CERT_EUROPE_SIMPLE = 'oo.simple'

export const SIGNATURE_TYPE_WITH_AUTH = [
  SIGNATURE_TYPE_DOCUSIGN_ADVANCED,
  SIGNATURE_TYPE_DOCUSIGN_QUALIFIED,
  SIGNATURE_TYPE_CERT_EUROPE_ADVANCED,
]

export const SIGNATURE_TYPE_DISPLAY_ORDER = [
  SIGNATURE_TYPE_DOCUSIGN_SIMPLE,
  SIGNATURE_TYPE_DOCUSIGN_ADVANCED,
  SIGNATURE_TYPE_DOCUSIGN_QUALIFIED,
  SIGNATURE_TYPE_CERT_EUROPE_SIMPLE,
  SIGNATURE_TYPE_CERT_EUROPE_ADVANCED,
  SIGNATURE_TYPE_HANDWRITTEN,
]

export enum ENVELOPE_STATUS {
  DRAFT = 'draft',
  PENDING = 'pending',
  PREVOIDED = 'pre-voided',
  QUEUED = 'queued',
  SENDING = 'sending',
  SENT = 'sent',
  SENT_PARTIAL = 'sent_partial',
  SIGNED = 'signed',
  TEMP = 'temp',
  UNVALIDATED = 'unvalidated',
  VOIDED = 'voided',
 }

export enum ENVELOPE_TYPES {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

export enum SIGNATURE_TYPES {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

export enum SIGNATURE_PROVIDER {
  DOCUSIGN = 'ds',
  OODRIVE = 'oo',
}
