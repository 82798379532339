var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.to ? 'router-link' : 'button',_vm._g(_vm._b({tag:"component",staticClass:"app-button",class:{
           'app-button--small': _vm.size === 'small',
           'app-button--medium': _vm.size === 'medium',
           'app-button--primary': _vm.type === 'primary',
           'app-button--outlined': _vm.type === 'outlined',
           'app-button--tonal': _vm.type === 'tonal',
           'app-button--ghost': _vm.type === 'ghost',
           'app-button--icon': _vm.type === 'icon',
           'app-button--counter': _vm.type === 'counter',
           'app-button--full-width': _vm.fullWidth,
           'app-button--brand': _vm.variant === 'brand',
           'app-button--project': _vm.variant === 'project',
           'app-button--dataroom': _vm.variant === 'dataroom',
           'app-button--archive': _vm.variant === 'archive',
           'app-button--neutral': _vm.variant === 'neutral',
           'app-button--danger': _vm.variant === 'danger',
           'app-button--is-loading': _vm.loading,
        },attrs:{"type":"button","to":_vm.to}},'component',_vm.$attrs,false),_vm.$listeners),[_c('span',{staticClass:"d-flex align-center column-gap-2",style:({ visibility: _vm.contentVisibility })},[(_vm.$slots['left-icon'])?_c('span',{staticClass:"body-1 mr-2"},[_vm._t("left-icon")],2):_vm._e(),_vm._t("default"),_vm._t("right-icon")],2),_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"indeterminate":"","width":"3"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }