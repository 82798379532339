import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import { SET_MINI } from '@/store/modules/global/mutation_types'

import actions from './actions'
import getters from './getters'
import { appUploadHandler } from './modules/app-upload-handler/appUploadHandler'
import archives from './modules/archives'
import bibles from './modules/bibles'
import { certeuropeSign } from './modules/certeurope-sign'
import checklist from './modules/checklist'
import { REMOVE_FROM_EXPAND_TASK_AFTER_DELETE, SET_TITLES_EXPAND_STATE } from './modules/checklist/mutation_types'
import { documentSharePicker } from './modules/document-share-picker/document-share-picker'
import { documents } from './modules/documents/documents'
import { documentsBreadcrumb } from './modules/documents-breadcrumb/documentsBreadcrumb'
import { documentsCreateNewFolder } from './modules/documents-create-new-folder/documentsCreateNewFolder'
import { documentsDelete } from './modules/documents-delete/documentsDelete'
import documentsDownloadFiles from './modules/documents-download-files'
import { documentsRename } from './modules/documents-rename/documentsRename'
import { documentsUploadFiles } from './modules/documents-upload-files/documentsUploadFiles'
import envelopeTemplates from './modules/envelope-templates'
import envelopes from './modules/envelopes'
import files from './modules/files'
import global from './modules/global'
import { groups } from './modules/groups'
import imanage from './modules/imanage'
import { SET_AUTH_CODE, SET_AUTH_STATUS } from './modules/imanage/mutation_types'
import { login } from './modules/login/login'
import { SET_USER_TOKEN_SCOPE } from './modules/login/mutation_types'
import logs from './modules/logs'
import maintenance from './modules/maintenance'
import payment from './modules/payment'
import polyOffice from './modules/poly-office'
import prepareSignature from './modules/prepareSignature'
import { projectsCreateNewFolder } from './modules/projects-create-new-folder/projectsCreateNewFolder'
import { projectsFolders } from './modules/projects-folders/projectsFolders'
import { projectsFoldersDelete } from './modules/projects-folders-delete/projectsFoldersDelete'
import { projectsFoldersMove } from './modules/projects-folders-move/projectsFoldersMove'
import { projectsFoldersRename } from './modules/projects-folders-rename/projectsFoldersRename'
import questionAnswer from './modules/question-answer'
import {
  ADD_CHASE_TIMER_ENVELOPE,
  ADD_CHASE_TIMER_GLOBAL,
  ADD_CHASE_TIMER_SIGNATURE,
  SET_LAST_ACCESS_TIMESTAMP,
  SET_LAST_MNEMO_ACCESSED,
} from './modules/room/mutation_types'
import { room } from './modules/room/room'
import { SET_DISPLAY_TYPE } from './modules/rooms/mutation_types'
import { rooms } from './modules/rooms/rooms'
import signature from './modules/signature'
import { signedDocuments } from './modules/signed-documents/signedDocuments'
import {
  REMOVE_FROM_EXPAND_TITLE_AFTER_DELETE,
  SET_EXPAND_SIGNING_CHECKLIST,
  SET_QUALIFIED_DS_WARNING_IS_MUTED,
} from './modules/signing-checklist/mutation_types'
import { signingChecklist } from './modules/signing-checklist/signingChecklist'
import subscription from './modules/subscription'
import todo from './modules/todo'
import user from './modules/user'
import { SET_USER_PROFILE } from './modules/user/mutation_types'
import mutations from './mutations'
import state from './state'

Vue.use(Vuex)

export const STORE_PERSIST_KEY = 'store-persist'

const PERSISTING_MUTATIONS = [
  'rooms/' + SET_DISPLAY_TYPE,
  'room/' + ADD_CHASE_TIMER_ENVELOPE,
  'room/' + ADD_CHASE_TIMER_GLOBAL,
  'room/' + ADD_CHASE_TIMER_SIGNATURE,
  'room/' + SET_LAST_ACCESS_TIMESTAMP,
  'room/' + SET_LAST_MNEMO_ACCESSED,
  'documents/' + SET_LAST_ACCESS_TIMESTAMP,
  'documents/' + SET_LAST_MNEMO_ACCESSED,
  'user/' + SET_USER_PROFILE,
  'login/' + SET_USER_TOKEN_SCOPE,
  'imanage/' + SET_AUTH_STATUS,
  'imanage/' + SET_AUTH_CODE,
  'global/' + SET_MINI,
  'signingChecklist/' + SET_QUALIFIED_DS_WARNING_IS_MUTED,
  'checklist/' + SET_TITLES_EXPAND_STATE,
  'checklist/' + REMOVE_FROM_EXPAND_TASK_AFTER_DELETE,
  'signingChecklist/' + SET_EXPAND_SIGNING_CHECKLIST,
  'signingChecklist/' + REMOVE_FROM_EXPAND_TITLE_AFTER_DELETE,
]

const vuexLocalStorage = new VuexPersistence({
  key: STORE_PERSIST_KEY,
  storage: window.localStorage,
  // What we want to keep
  reducer: (state) => ({
    rooms: {
      displayType: state.rooms.displayType,
    },
    room: {
      chaseTimers: state.room.chaseTimers,
      lastAccessTimestamp: state.room.lastAccessTimestamp,
      lastMnemoAccessed: state.room.lastMnemoAccessed,
    },
    documents: {
      lastAccessTimestamp: state.documents.lastAccessTimestamp,
      lastMnemoAccessed: state.documents.lastMnemoAccessed,
    },
    user: {
      profile: state.user.profile,
    },
    login: {
      userTokenScope: state.login.userTokenScope,
    },
    imanage: {
      authStatus: state.imanage.authStatus,
      authCode: state.imanage.authCode,
    },
    global: {
      mini: state.global.mini,
    },
    signingChecklist: {
      qualifiedDSWarningIsMuted: state.signingChecklist.qualifiedDSWarningIsMuted,
      isExpandSigningChecklist: state.signingChecklist.isExpandSigningChecklist,
    },
    checklist: {
      titlesExpandState: state.checklist.titlesExpandState,
    },
  }),
  // On which mutations we want to persist
  filter: (mutation) => PERSISTING_MUTATIONS.includes(mutation.type),
})

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    envelopeTemplates,
    polyOffice,
    questionAnswer,
    documentsDownloadFiles,
    maintenance,
    global,
    logs,
    payment,
    imanage,
    subscription,
    certeuropeSign,
    signature,
    files,
    signingChecklist,
    prepareSignature,
    checklist,
    bibles,
    documents,
    login,
    envelopes,
    todo,
    room,
    rooms,
    archives,
    user,
    groups,
    documentSharePicker,
    documentsBreadcrumb,
    documentsCreateNewFolder,
    projectsFolders,
    projectsCreateNewFolder,
    projectsFoldersDelete,
    projectsFoldersRename,
    projectsFoldersMove,
    documentsDelete,
    documentsUploadFiles,
    appUploadHandler,
    documentsRename,
    signedDocuments,
  },
  plugins: [vuexLocalStorage.plugin],
})
