<template>
  <v-card>
    <v-card-title>
      {{ $t('project.members.AddMemberForm.memberFormTitle') }}
      <AppButton
        class="ml-auto mr-n5 mt-n6"
        icon
        @click="cancelForm"
      >
        <font-awesome-icon :icon="['fal', 'xmark']" size="lg" />
      </AppButton>
    </v-card-title>
    <v-card-subtitle class="font-weight-medium black--text pt-2">{{ $t('project.members.AddMemberForm.group', { groupName: group.name }) }}</v-card-subtitle>

    <v-card-text>
      <div v-if="isCurrentUserPm && isSubPayingUser" class="mb-6">
        <div v-if="subscriptionTeamsPending" class="text-center">
          <AppLoader small></AppLoader>
        </div>
        <AddMemberType v-else-if="subscriptionTeams && subscriptionTeams.length"
                       :defaultMemberType="addMemberType"
                       @change-add-member-type="changeMemberType"
        >
        </AddMemberType>
      </div>

      <v-row>
        <v-col>
          <form novalidate @submit.stop.prevent="submitForm">
            <template v-if="addMemberType === MEMBER_TYPE_OPTIONS.NEW_MEMBER">
              <div class="member-add-dialog-required-field mb-4" v-html="$t('common.requiredFieldsInfo')"></div>
              <v-row>
                <v-col>
                  <AppCombobox :value="memberForm.firstName"
                               :items="selfContactList"
                               item-value="id"
                               item-text="firstName"
                               return-object
                               class="AddMember-known-users-input required"
                               :label="$t('common.form.firstName')"
                               :error-messages="firstNameErrors"
                               :filter="filterFunction"
                               :menu-props="{ contentClass: 'AddMember-known-users-menu' }"
                               @input="onFormInput($event, 'firstName')"
                               @blur="$v.memberForm.firstName.$touch"
                  >
                    <template #item="{item}">
                      <AddMemberFormComboboxItem :item="item"/>
                    </template>
                  </AppCombobox>
                </v-col>
                <v-col>
                  <AppCombobox :value="memberForm.lastName"
                               :items="selfContactList"
                               :label="$t('common.form.lastName')"
                               :filter="filterFunction"
                               item-text="lastName"
                               item-value="id"
                               return-object
                               class="AddMember-known-users-input required"
                               :menu-props="{ contentClass: 'AddMember-known-users-menu' }"
                               :error-messages="lastNameErrors"
                               @input="onFormInput($event, 'lastName')"
                               @blur="$v.memberForm.lastName.$touch"
                  >
                    <template #item="{item}">
                      <AddMemberFormComboboxItem :item="item"/>
                    </template>
                  </AppCombobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <AppCombobox :value="memberForm.company"
                               :items="selfContactList"
                               :label="$t('common.form.company')"
                               :filter="filterFunction"
                               item-text="company"
                               item-value="id"
                               return-object
                               class="AddMember-known-users-input"
                               :menu-props="{ contentClass: 'AddMember-known-users-menu' }"
                               @input="onFormInput($event, 'company')"
                    >
                    <template #item="{item}">
                      <AddMemberFormComboboxItem :item="item"/>
                    </template>
                  </AppCombobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <AppCombobox :value="memberForm.email"
                               :items="selfContactList"
                               :label="$t('common.form.email')"
                               :filter="filterFunction"
                               item-text="email"
                               item-value="id"
                               return-object
                               class="AddMember-known-users-input required"
                               :error-messages="emailErrors"
                               :menu-props="{ contentClass: 'AddMember-known-users-menu' }"
                               @input="onFormInput($event, 'email')"
                               @blur="$v.memberForm.email.$touch"
                  >
                    <template #item="{item}">
                      <AddMemberFormComboboxItem :item="item"/>
                    </template>
                  </AppCombobox>
                </v-col>
                <v-col>
                  <AppSelect :value="memberForm.locale"
                             :items="localeOptions"
                             class="required"
                             :label="$t('common.form.locale')"
                             :error-messages="localeErrors"
                             @change="onFormInput($event, 'locale')"
                             @blur="$v.memberForm.locale.$touch"
                  >
                    <template #selection="{item}">
                      <div class="d-flex align-center">
                        <v-img :src="item.icon" width="25" class="mr-2"></v-img>
                        <span>{{ item.text }}</span>
                      </div>
                    </template>
                    <template #item="{item}">
                      <div class="d-flex align-center">
                        <v-img :src="item.icon" width="25" class="mr-2"></v-img>
                        <span>{{ item.text }}</span>
                      </div>
                    </template>
                  </AppSelect>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <AddMemberFromTeam :model="$v"
                                 :memberForm="memberForm"
                                 @input="onFormInput($event, 'team')"
              />
            </template>

            <v-divider class="my-6"></v-divider>

            <v-row>
              <v-col class="pt-0">
                <v-checkbox :input-value="memberForm.sendInvitationNow"
                            class="mt-0"
                            dense
                            hide-details
                            :label="$t('project.members.AddMemberForm.sendInvitationNow')"
                            @change="onFormInput(!!$event, 'sendInvitationNow')"
                />
              </v-col>
            </v-row>
            <v-row v-if="!currentRoom.isDataroom">
              <v-col class="pt-0">
                <v-checkbox :input-value="memberForm.askForIdCheck"
                            class="mt-0"
                            dense
                            hide-details
                            :label="$t('project.members.AddMemberForm.askForIdCheck')"
                            @change="onFormInput(!!$event, 'askForIdCheck')"
                />
              </v-col>
            </v-row>

            <v-row v-if="isProjectManager">
              <v-col class="pt-0">
                <v-checkbox :input-value="memberForm.projectManagersAreHidden"
                            dense
                            class="mt-0"
                            hide-details
                            :label="$t('project.members.AddMemberForm.hideProjectManager')"
                            @change="onFormInput(!!$event, 'projectManagersAreHidden')"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col class="py-0 d-flex align-center">
                <v-switch :input-value="memberForm.withCustomInvitationMessage"
                          :label="$t('project.members.AddMemberForm.addCustomInvitationMessage')"
                          :disabled="!memberForm.sendInvitationNow"
                          hide-details
                          class="my-0 py-0"
                          @change="onFormInput(!!$event, 'withCustomInvitationMessage')"
                />

                <AppButton text
                           class="mx-2 text--primary text-decoration-underline"
                           :disabled="!memberForm.sendInvitationNow"
                           @click="previewInvitation"
                >
                  {{ $t('project.members.AddMemberForm.previewLink') }}
                </AppButton>
              </v-col>
            </v-row>
            <template v-if="memberForm.withCustomInvitationMessage">
              <v-row>
                <v-col class="py-0">
                  <VisualTextEditor :value="memberForm.customInvitationMessage"
                                    :error-messages="customMessageErrors"
                                    :max-length="customMessageMaxLength"
                                    counter="footer"
                                    @blur="$v.memberForm.customInvitationMessage.$touch"
                                    @input="onFormInput($event, 'customInvitationMessage')"
                  />
                </v-col>
              </v-row>
            </template>

            <input type="submit" class="d-none">
          </form>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <AppButton @click="cancelForm">
        {{$t('common.cancel')}}
      </AppButton>
      <AppButton color="primary"
                 :loading="okLoading"
                 @click="submitForm"
      >
        {{$t('common.add')}}
      </AppButton>
    </v-card-actions>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import AppCombobox from '@/common/AppCombobox.vue'
import AppLoader from '@/common/AppLoader.vue'
import AppSelect from '@/common/AppSelect.vue'
import AppButton from '@/common/buttons/AppButton.vue'
import { emailFormatter, escapeHtml } from '@/common/utils/strings'
import VisualTextEditor from '@/common/visual-text-editor/VisualTextEditor'
import { GET_SUBSCRIPTION_TEAMS } from '@/store/modules/subscription/action_types'
import { GET_SELF_CONTACT_LIST } from '@/store/modules/user/action_types'
import { ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

import AddMemberFormComboboxItem from './AddMemberFormComboboxItem.vue'
import AddMemberFromTeam from './AddMemberFromTeam.vue'
import AddMemberType from './AddMemberType.vue'
import { MEMBER_TYPE_OPTIONS } from './types.ts'

const CUSTOM_MESSAGE_MAX_LENGTH = 400

export default {
  name: 'AddMemberForm',
  components: {
    AddMemberFromTeam,
    AddMemberType,
    AppLoader,
    AddMemberFormComboboxItem,
    AppButton,
    AppCombobox,
    AppSelect,
    VisualTextEditor,
  },
  mixins: [validationMixin],
  props: {
    group: {
      type: Object,
      required: true,
    },
    memberForm: {
      type: Object,
      required: true,
    },
    okLoading: {
      type: Boolean,
      default: false,
    },
  },
  validations () {
    if (this.addMemberType === MEMBER_TYPE_OPTIONS.NEW_MEMBER) {
      return {
        memberForm: {
          firstName: { required },
          lastName: { required },
          email: {
            required,
            email: (val) => email(emailFormatter(val)),
          },
          locale: { required },
          customInvitationMessage: {
            maxLength: (message) => !this.memberForm.withCustomInvitationMessage || this.customMessageIsTooLong,
          },
        },
      }
    } else {
      return {
        memberForm: {
          team: {
            required,
          },
          customInvitationMessage: {
            maxLength: (message) => !this.memberForm.withCustomInvitationMessage || this.customMessageIsTooLong,
          },
        },
      }
    }
  },
  data () {
    return {
      MEMBER_TYPE_OPTIONS,
      addMemberType: MEMBER_TYPE_OPTIONS.NEW_MEMBER,
      customMessageMaxLength: CUSTOM_MESSAGE_MAX_LENGTH,
      localeOptions: [
        {
          text: this.$t('project.members.AddMemberForm.locale.french'),
          value: 'fr',
          icon: '/img/icons/fr.svg',
        },
        {
          text: this.$t('project.members.AddMemberForm.locale.english'),
          value: 'en',
          icon: '/img/icons/en.svg',
        },
        {
          text: this.$t('project.members.AddMemberForm.locale.german'),
          value: 'de',
          icon: '/img/icons/de.svg',
        },
      ],
    }
  },
  computed: {
    ...mapState('user', ['selfContactList']),
    ...mapState('room', ['currentRoom']),
    ...mapState('subscription', ['subscriptionTeamsPending', 'subscriptionTeams']),
    ...mapGetters('room', ['isCurrentUserPm']),
    ...mapGetters('user', ['isSubPayingUser']),
    isProjectManager () {
      return this.group.isPm
    },
    firstNameErrors () {
      const errors = []
      if (!this.$v.memberForm.firstName.$dirty) return errors
      !this.$v.memberForm.firstName.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('common.form.firstName') }))
      return errors
    },
    lastNameErrors () {
      const errors = []
      if (!this.$v.memberForm.lastName.$dirty) return errors
      !this.$v.memberForm.lastName.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('common.form.lastName') }))
      return errors
    },
    localeErrors () {
      const errors = []
      if (!this.$v.memberForm.locale.$dirty) return errors
      !this.$v.memberForm.locale.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('common.form.locale') }))
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.memberForm.email.$dirty) return errors
      !this.$v.memberForm.email.email && errors.push(this.$t('common.validations.email'))
      !this.$v.memberForm.email.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('common.form.email') }))
      return errors
    },
    customMessageErrors () {
      const errors = []
      if (!this.$v.memberForm.customInvitationMessage.$dirty) return errors
      !this.$v.memberForm.customInvitationMessage.maxLength && errors.push(this.$t('common.validations.textTooLong'))
      return errors
    },
    customMessageLength () {
      return escapeHtml(this.$v.memberForm.customInvitationMessage.$model).length
    },
    customMessageIsTooLong () {
      return this.customMessageMaxLength > this.customMessageLength
    },
  },
  created () {
    if (this.isCurrentUserPm && this.isSubPayingUser) {
      this.getSubscriptionTeams()
    }

    if (this.currentRoom.isDataroom) {
      this.memberForm.askForIdCheck = false
    }
  },
  async mounted () {
    await this.GET_SELF_CONTACT_LIST()
  },
  methods: {
    ...mapActions('user', [GET_SELF_CONTACT_LIST]),
    ...mapActions('subscription', [GET_SUBSCRIPTION_TEAMS]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR]),
    async getSubscriptionTeams () {
      try {
        await this.GET_SUBSCRIPTION_TEAMS()
      } catch (error) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('subscriptions.views.SubscriptionTeams.getTeamsError'))
      }
    },
    filterFunction (item, queryText) {
      return item.firstName?.toLowerCase().includes(queryText.toLowerCase()) ||
        item.lastName?.toLowerCase().includes(queryText.toLowerCase()) ||
        item.company?.toLowerCase().includes(queryText.toLowerCase()) ||
        item.email?.toLowerCase().includes(queryText.toLowerCase())
    },
    onFormInput (value, property) {
      switch (typeof value) {
        case 'string': {
          this.$emit('update:memberForm', {
            ...this.memberForm,
            [property]: value.trim(),
          })
          break
        }
        case 'boolean': {
          this.$emit('update:memberForm', {
            ...this.memberForm,
            [property]: value,
          })
          break
        }
        case 'object': {
          if (value) {
            if (this.addMemberType === MEMBER_TYPE_OPTIONS.NEW_MEMBER) {
              this.$emit('update:memberForm', {
                ...this.memberForm,
                firstName: value.firstName?.trim(),
                lastName: value.lastName?.trim(),
                email: value.email?.trim(),
                company: value.company?.trim(),
                locale: value.locale?.trim(),
              })
            } else {
              this.$emit('update:memberForm', {
                ...this.memberForm,
                team: value,
              })
            }
          }
          break
        }
      }
    },
    changeMemberType (value) {
      this.addMemberType = value
      this.$emit('change-add-member-type', value)
    },
    previewInvitation () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('previewInvitation')
      }
    },
    cancelForm () {
      this.$emit('onCancel')
    },
    submitForm () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        // We wait for all potential input events to be processed
        // An immediate timeout shares the same stack as the events,
        // so it's processed _after_ the events already scheduled to be handled
        setTimeout(() => {
          this.$emit('onValidate')
        }, 0)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.member-add-dialog-required-field {
  color: #333;
}

.AddMember-known-users-menu {
  .v-list-item {
    border-bottom: 1px solid #eee;
    padding-top: 6px;
    padding-bottom: 6px !important;
  }
}

.AddMember-known-users-input {
  ::v-deep .v-input__append-inner {
    display: none;
  }
}
</style>
