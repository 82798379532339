import {
  GET_DATA_ROOM_CUSTOM_PRICING,
  GET_DATA_ROOM_PRICING,
  GET_LAST_VISITED_ROOMS,
  GET_ROOMS_PROGRESSIVELY,
  GET_ROOM_LABELS,
  GET_SUBSCRIPTION_LABELS,
  GET_RECENT_ROOMS,
  GET_ROOM_SEARCH_RESULTS,
  GET_ROOM_SEARCH_RESULTS_PROGRESSIVELY,
} from './action_types'
import {
  SET_DATA_ROOM_CUSTOM_PRICING,
  SET_DATA_ROOM_CUSTOM_PRICING_ERROR,
  SET_DATA_ROOM_CUSTOM_PRICING_PENDING,
  SET_DATA_ROOM_PRICING,
  SET_DATA_ROOM_PRICING_ERROR,
  SET_DATA_ROOM_PRICING_PENDING,
  SET_GET_ROOM_LABELS_ERROR,
  SET_GET_ROOM_LABELS_PENDING,
  SET_LAST_VISITED_ROOMS,
  SET_LAST_VISITED_ROOMS_PENDING,
  SET_ROOMS_LABELS,
  SET_SUBSCRIPTION_LABELS,
  SET_SUBSCRIPTION_LABELS_ERROR,
  SET_SUBSCRIPTION_LABELS_PENDING,
  SET_RECENT_ROOMS,
  SET_RECENT_ROOMS_PENDING,
  SET_RECENT_ROOMS_ERROR,
  SET_ROOM_SEARCH_RESULTS,
  SET_ROOM_SEARCH_RESULTS_PENDING,
  SET_ROOM_SEARCH_RESULTS_ERROR,
  SET_SEARCH_MODE_ENABLED,
} from './mutation_types'
import roomsService from '../../../services/rooms.service'
import { SET_USER_CAN_CREATE_ROOMS } from '../user/mutation_types'

export const actions = {
  async [GET_SUBSCRIPTION_LABELS] ({ commit }, config) {
    commit(SET_SUBSCRIPTION_LABELS_PENDING, true)
    try {
      const response = await roomsService.getSubscriptionLabels(config)
      commit(SET_SUBSCRIPTION_LABELS, response.data)
    } catch (error) {
      commit(SET_SUBSCRIPTION_LABELS_ERROR, error)
      throw error
    } finally {
      commit(SET_SUBSCRIPTION_LABELS_PENDING, false)
    }
  },
  async [GET_DATA_ROOM_CUSTOM_PRICING] ({ commit }, data) {
    commit(SET_DATA_ROOM_CUSTOM_PRICING_PENDING, true)
    try {
      const response = await roomsService.getDataRoomCustomPricing(data)
      commit(SET_DATA_ROOM_CUSTOM_PRICING, response.data)
    } catch (error) {
      commit(SET_DATA_ROOM_CUSTOM_PRICING_ERROR, error)
      throw error
    } finally {
      commit(SET_DATA_ROOM_CUSTOM_PRICING_PENDING, false)
    }
  },
  async [GET_DATA_ROOM_PRICING] ({ commit }) {
    commit(SET_DATA_ROOM_PRICING_PENDING, true)
    try {
      const response = await roomsService.getDataRoomPricing()
      commit(SET_DATA_ROOM_PRICING, response.data)
    } catch (error) {
      commit(SET_DATA_ROOM_PRICING_ERROR, error)
      throw error
    } finally {
      commit(SET_DATA_ROOM_PRICING_PENDING, false)
    }
  },
  /**
   * @function getRooms
   * @param {Boolean} mini
   * @param {store} store
   */
  getRooms: async function ({ commit }, queryObject = {}) {
    commit('SET_PROCESSING', true)
    try {
      const response = await roomsService.getRooms(queryObject)
      commit('SET_ROOMS', response.data)
      commit('SET_LOADING_ROOMS_INFO', queryObject.mini || false)
    } catch (error) {
      commit('SET_ERROR', error)
      throw error
    } finally {
      commit('SET_PROCESSING', false)
    }
  },
  // Get mini first for quick loading
  [GET_ROOMS_PROGRESSIVELY] ({ dispatch }) {
    dispatch('getRooms', {
      roomCounters: false,
      mini: true,
    })
      .then(() => {
        dispatch('getRooms', {
          roomCounters: true,
          mini: false,
        })
      })
  },
  /**
   * @function getRoomTypes
   * @param {store} store
   */
  getRoomTypes: function ({ commit }) {
    commit('SET_PROCESSING', true)
    roomsService.getRoomTypes()
      .then(response => {
        commit('SET_ROOM_TYPES', response.data)
        commit('user/' + SET_USER_CAN_CREATE_ROOMS, response.data && response.data.length > 0, { root: true })
      }).finally(() => {
        commit('SET_PROCESSING', false)
      })
  },
  async [GET_ROOM_LABELS] ({ commit }, mnemo) {
    commit(SET_GET_ROOM_LABELS_PENDING, true)
    try {
      const response = await roomsService.getRoomLabels(mnemo)
      commit(SET_ROOMS_LABELS, response.data)
    } catch (error) {
      commit(SET_GET_ROOM_LABELS_ERROR, error)
      throw error
    } finally {
      commit(SET_GET_ROOM_LABELS_PENDING, false)
    }
  },
  async [GET_LAST_VISITED_ROOMS] ({ commit }, limit) {
    commit(SET_LAST_VISITED_ROOMS_PENDING, true)

    try {
      const response = await roomsService.getLastVisitedRooms({
        limit,
      })
      const { rooms } = response.data

      commit(SET_LAST_VISITED_ROOMS, rooms)
    } finally {
      commit(SET_LAST_VISITED_ROOMS_PENDING, false)
    }
  },
  async [GET_RECENT_ROOMS] ({ commit }, { limit, roomCounters = true }) {
    commit(SET_RECENT_ROOMS_PENDING, true)
    try {
      const response = await roomsService.getLastVisitedRooms({
        limit,
        roomCounters,
      })
      const { rooms } = response.data
      commit(SET_RECENT_ROOMS, rooms)
    } catch (error) {
      console.error(error)
      commit(SET_RECENT_ROOMS_ERROR, error)
      throw error
    } finally {
      commit(SET_RECENT_ROOMS_PENDING, false)
    }
  },
  async [GET_ROOM_SEARCH_RESULTS] ({ commit, state }, queryParams = {}) {
    if (queryParams.search) {
      commit(SET_ROOM_SEARCH_RESULTS_PENDING, true)
      commit(SET_SEARCH_MODE_ENABLED, true)
      try {
        const response = await roomsService.getRooms(queryParams)
        commit(SET_ROOM_SEARCH_RESULTS, response.data)
      } catch (error) {
        console.error(error)
        commit(SET_ROOM_SEARCH_RESULTS_ERROR, error)
        throw error
      } finally {
        commit(SET_ROOM_SEARCH_RESULTS_PENDING, false)
      }
    } else {
      commit(SET_ROOM_SEARCH_RESULTS, [])
      commit(SET_SEARCH_MODE_ENABLED, false)
    }
  },
  // Get mini first for quick loading, just like the home.
  async [GET_ROOM_SEARCH_RESULTS_PROGRESSIVELY] ({ dispatch }, search) {
    await dispatch(GET_ROOM_SEARCH_RESULTS, {
      roomCounters: false,
      mini: true,
      search,
    })
    dispatch(GET_ROOM_SEARCH_RESULTS, {
      roomCounters: true,
      mini: false,
      search,
    })
  },
}
